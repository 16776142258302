import React, { useEffect, useState } from 'react'
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Loader from '../components/Loader'
import ListItem from '../components/ListItem'

const OffersPage = () => {
	const data = useStaticQuery(graphql`
		query AllOffersQuery {
			allWordpressWpOffer(sort: {fields: date, order: DESC}) {
				nodes {
					title
					content
					id
					area
					acf {
						link
					}
				}
			}
			allWordpressWpArea {
				nodes {
					name
					wordpress_id
				}
			}
			wordpressPage(slug: { eq: "erbjudanden" }) {
				id
				title
				content
				featured_media {
					localFile {
						childImageSharp {
							fluid(maxWidth: 1000, quality: 100) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			}
		}
	`)
	const info = data.allWordpressWpOffer.nodes
	const areas = data.allWordpressWpArea.nodes
	const pageData = data.wordpressPage
	const [checked, setChecked] = useState(false)
	const [filters, setFilters] = useState([])
	const [filtersList, setFiltersList] = useState([])
	const [items, setItems] = useState([])
	const [isReseted, setIsReseted] = useState(false)

	const handleFilters = filter => {
		const tmpFilters = [...filters]
		if (tmpFilters.includes(filter)) {
			const index = tmpFilters.indexOf(filter)
			if (index !== -1) {
				tmpFilters.splice(index, 1)
			}
		} else {
			tmpFilters.push(filter)
		}
		setFilters(tmpFilters)
	}

	const handleReset = () => {
		setIsReseted(true)
		setFilters([])
	}

	useEffect(() => {
		const tmpFilters = [...filters]
		if (tmpFilters.length > 0) {
			const filtered = []
			info.forEach(item => {
				const categories = item.area.map(subItem => {
					const areaId = areas.filter(
						item => item.wordpress_id === subItem
					)
					return areaId[0].name
				})
				if (filters.some(i => categories.includes(i))) {
					filtered.push(item)
				}
			})
			setItems(filtered)
		} else {
			setItems(info)
		}
	}, [filters, areas, info])

	useEffect(() => {
		if (areas.length > 0) {
			const mappedFilters = []
			info.forEach(item => {
				item.area.forEach(subItem => {
					const areaId = areas.filter(
						item => item.wordpress_id === subItem
					)
					if (!mappedFilters.includes(areaId[0].name)) {
						mappedFilters.push(areaId[0].name)
					}
				})
			})
			setFiltersList(mappedFilters)
		}
	}, [areas, info])
	return (
		<Layout>
			<SEO title={pageData.title} metaTitle='Business Halland' description='Business Halland gör det enklare för dig som företagare att hitta och ta del av de erbjudan och evenemang i Halland.' />
			<div className="bg-green-bg w-full pt-10 pb-10">
				<div className="max-w-screen-2xl px-6 lg:px-10 xl:px-20 mx-auto">
					<div className="w-auto block">
						<button
							className="text-green-accent text-previous font-bold underline mb-12 flex items-center focus:outline-none"
							onClick={() => navigate(-1)}
						>
							<HiArrowLeft className="mr-2" />
							Gå tillbaka
						</button>
					</div>
					<div className="flex flex-col-reverse md:flex-row w-full mb-16">
						<div className="mt-4 md:mt-0 w-full md:w-1/2">
							<h1 className="text-title text-green-accent font-bold mb-5 mr-16">
								{pageData.title}
							</h1>
							<p
								className="text-base font-normal text-body mb-5 mr-16"
								dangerouslySetInnerHTML={{
									__html: pageData.content,
								}}
							/>
						</div>
						<div className="w-full md:w-1/2 flex justify-end">
							<Img
								className="h-full w-full rounded-xl max-h-thumb"
								fluid={
									pageData?.featured_media?.localFile
										?.childImageSharp?.fluid
								}
							/>
						</div>
					</div>
					<div className="flex flex-wrap flex-col w-full">
						<button
							onClick={() => setChecked(!checked)}
							className="text-lg font-medium bg-white rounded-xl px-6 py-4 shadow-sm flex items-center justify-between w-full sm:w-tablet xl:w-box mb-4 focus:outline-none"
						>
							Filtrera
							{checked ? <FiChevronUp /> : <FiChevronDown />}
						</button>
						<div
							className={`dropdown absolute mt-20 text-base font-normal bg-white rounded-xl px-6 pt-4 pb-0 shadow-sm w-90p sm:w-tablet xl:w-box max-h-dropdown overflow-y-scroll z-50 ${!checked &&
								'hidden'}`}
						>
							<ul className={`space-y-2 ${!checked && 'hidden'}`}>
								{filtersList.map(el => (
									<ListItem
										key={el}
										name={el}
										handleFilters={handleFilters}
										isReseted={isReseted}
										setIsReseted={setIsReseted}
									/>
								))}
							</ul>
							<div className="mt-4 flex flex-row space-x-2 sticky py-4 bottom-0 bg-white">
								<button
									onClick={() => handleReset()}
									className="text-xs text-white font-medium bg-black rounded-xl px-4 lg:px-12 py-3 items-center focus:outline-none"
								>
									Ta bort gjorda val
								</button>
								<button
									onClick={() => setChecked(!checked)}
									className="text-xs text-white font-medium bg-green-accent rounded-xl px-4 lg:px-16 py-3 items-center focus:outline-none"
								>
									Stäng
								</button>
							</div>
						</div>
					</div>
					<div className="flex w-full mt-8">
						{items.length === 0 ? (
							<div className="w-full flex items-center justify-center">
								<Loader />
							</div>
						) : (
							<div className="flex flex-wrap grid-cols-3 gap-6">
								{items.map(el => (
									<div
										key={el.id}
										className="flex flex-col justify-between w-full md:w-box bg-white rounded-xl shadow-sm py-6 px-4 box-info"
									>
										<div className="flex flex-col">
											<div className="w-full flex flex-wrap">
												{el.area.map(subEl => {
													const areaId = areas.filter(
														item =>
															item.wordpress_id ===
															subEl
													)
													return (
														<span
															key={subEl}
															className="text-gray-accent text-xs category relative"
															dangerouslySetInnerHTML={{
																__html:
																	areaId[0].name,
															}}
														/>
													)
												})}
											</div>
											<h2 className="text-lg font-bold pb-3" dangerouslySetInnerHTML={{
												__html:
												el.title
											}}/>
											<div className="pb-5 text-sm" dangerouslySetInnerHTML={{
												__html:
												el.content
											}}/>
										</div>
										<a
											className="underline text-xs font-medium flex items-center justify-between"
											href={el?.acf?.link}
											target="_blank"
											rel="noreferrer"
										>
											Läs mer
											<HiArrowRight className="text-green-accent text-lg box-info-arrow" />
										</a>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default OffersPage
